<template>
  <div id="index">
    <div class="page-container paddclose">
      <div class="header-nav">
        <i @click="goBack"></i>
        <h1>维权超市</h1>
      </div>
      <div class="ykyy-main">
        <div class="banner">
          <img src="http://dssq.ikeqiao.net/data/image/h5/images/protect-pic1.png" style="height:8.4rem" />
        </div>
        <div class="content-box" style="top:7rem">
          <div class="img">
            <img
              src="https://wxbucket.oss-cn-hangzhou.aliyuncs.com/zhsq/2QmVlkFNDPI8.png"
            />
          </div>
          <div class="wxparse-box">
            社区妇联是社区的妇联组织，共同关注和解答居民朋友生活健康、社会生活等方面的热点难点疑点问题，引导广大居民朋友积极向上的生活态度和健康的生活方式。此外，还联合社区法律顾问，通过宣传、讲座等形式，推广妇女儿童有关知识，帮助妇女儿童解决法律困惑，保护合法权益。
          </div>
          <div class="btn-group">
            <span @click="gotoLink('smfw')">上门服务</span>
            <span @click="gotoLink('yyjd')">预约解答</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {},
  methods: {
    gotoLink(path) {
      this.$router.push(path);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
@import "~assets/scss/index.scss";
</style>
