import { render, staticRenderFns } from "./guideIndex.vue?vue&type=template&id=474e4367&scoped=true&"
import script from "./guideIndex.vue?vue&type=script&lang=js&"
export * from "./guideIndex.vue?vue&type=script&lang=js&"
import style0 from "./guideIndex.vue?vue&type=style&index=0&id=474e4367&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474e4367",
  null
  
)

export default component.exports